

































































































































































import { Component, Vue, Watch, Ref } from "vue-property-decorator";
import { ProjectInterface } from "@/types/project.type";
import { ProjectModule } from "@/store/modules/project-module";
import ClientModule from "@/store/modules/client-module";
import { ClientInterface } from "@/types/client.type";
import { UserInterface } from "@/types/user.type";
import { UserModule } from "@/store/modules/user-module";
import TimesheetReportModule from "@/store/modules/timesheet-report-module";
import WorktimeChart from "@/components/charts/WorktimeChart.vue";
import { TimesheetInterface } from "@/types/timesheet.type";
import { generateThirtyAgoRange } from "@/utils";
import TimeSheetTableForReport from "@/components/reports/TimeSheetTableForReport.vue";
import { VuetifyForm } from "@/types/vuetify.type";

interface ChartDatasetInterface {
  label: string;
  backgroundColor: string;
  hoverBackgroundColor: string;
  data: number[];
}

interface WorktimeInterface {
  labels: string[];
  datasets: ChartDatasetInterface[];
}

@Component({
  components: {
    WorktimeChart,
    TimeSheetTableForReport,
  },
})
export default class ReportComponent extends Vue {
  get worktimeChart(): WorktimeInterface {
    const timesheetsReport = TimesheetReportModule.timesheetReports;

    if (timesheetsReport?.reports) {
      let labels: string[] = Object.keys(timesheetsReport!.reports).map((k: string) => k);
      labels = labels.sort((a: string, b: string) => {
        const re = /([0-9]+)-([0-9]+)/;
        const aMatch = a.match(re);
        const bMatch = b.match(re);
        if (!aMatch || !bMatch) {
          return 0;
        }

        const aDate = new Date(Number(aMatch[1]), Number(aMatch[2]));
        const bDate = new Date(Number(bMatch[1]), Number(bMatch[2]));

        if (aDate > bDate) {
          return 1;
        } else {
          return -1;
        }
      });

      const data: number[] = [];
      labels.forEach((key: string) => {
        let totalTime = 0;
        timesheetsReport!.reports[key].forEach((report: TimesheetInterface) => {
          totalTime += report.duration;
        });

        const newTotalTime = Math.round((totalTime / (8 * 60 * 60) + Number.EPSILON) * 100) / 100;

        data.push(newTotalTime);
      });

      return {
        labels,
        datasets: [
          {
            label: "Total work day",
            backgroundColor: "#3B50F7",
            hoverBackgroundColor: "#F2AF38",
            data,
          },
        ],
      };
    }

    return {
      labels: [""],
      datasets: [
        {
          label: "",
          backgroundColor: "#3B50F7",
          hoverBackgroundColor: "#F2AF38",
          data: [],
        },
      ],
    };
  }

  get dateRangeText(): string {
    return this.filterDateRange
      .sort((a: string, b: string): number => Number(new Date(a)) - Number(new Date(b)))
      .join(" ~ ");
  }

  get clients(): ClientInterface[] {
    return ClientModule.clients;
  }

  get projects(): ProjectInterface[] {
    if (this.filterClients.length === 0) {
      return ProjectModule.projects;
    }
    return ProjectModule.projects.filter((p: ProjectInterface) => this.filterClients.includes(p.client!));
  }

  get users(): UserInterface[] {
    if (this.filterProjects.length === 0 && this.filterClients.length === 0) {
      return UserModule.users;
    }
    const allUsers = new Set(
      ProjectModule.projects
        .filter((p: ProjectInterface) => {
          return this.filterClients.includes(p.client!) || this.filterProjects.includes(p.id!);
        })
        .flatMap((p: ProjectInterface) => p.projectMembers?.flatMap((m) => m.user))
    );
    return UserModule.users.filter((user) => allUsers.has(user.id));
  }

  get timesheets() {
    const ret = [];
    const timesheets = TimesheetReportModule.timesheetReports?.reports;
    if (!timesheets) {
      return [];
    } // no timeSheet
    for (const yearMonth of Object.keys(timesheets)) {
      ret.push(...timesheets[yearMonth]);
    }
    return ret;
  }

  private filterDateRange: string[] = generateThirtyAgoRange();
  private dateRangeOpen: boolean = false;
  private filterProjects: number[] = [];
  private filterClients: number[] = [];
  private filterUsers: number[] = [];

  private dateRules = [(v: any) => v.includes("~") || "You must select Start date and End date"];

  private searchClient: string = "";
  private searchProject: string = "";
  private searchUser: string = "";

  private valid: boolean = false;
  @Ref()
  private form!: VuetifyForm;

  public async mounted() {
    await ProjectModule.getProjects();
    await ClientModule.getClients();
    await UserModule.getUsers({ noName: false, noProject: false });
    this.filterTimesheet();
  }

  private filterTimesheet() {
    this.form.validate();
    if (this.valid) {
      TimesheetReportModule.getTimesheetReports({
        filterDateRange: this.filterDateRange.length < 1 ? generateThirtyAgoRange() : this.filterDateRange,
        projects: this.filterProjects,
        clients: this.filterClients,
        users: this.filterUsers,
      });
    }
  }

  // Watch Selected Client
  @Watch("filterClients")
  private onFilterClientsChanged(value: string) {
    if (value.length <= 0) {
      return;
    }
    // Remove selected projects from the filterProjects list if it's not available in the projects list.
    const projectsIds = this.projects.map((p: ProjectInterface) => p.id);
    this.filterProjects = this.filterProjects.filter((pid: number) => projectsIds.includes(pid));
    // Remove selected user from the filterUsers list if it's not available in the users list.
    const userIds = this.users.map((u: UserInterface) => u.id);
    this.filterUsers = this.filterUsers.filter((uid: number) => userIds.includes(uid));
  }

  // Watch Selected Projects
  @Watch("filterProjects")
  private onFilterProjectsChanged(value: string) {
    if (value.length <= 0) {
      return;
    }
    // Remove selected user from the filterUsers list if it's not available in the users list.
    const userIds = this.users.map((u: UserInterface) => u.id);
    this.filterUsers = this.filterUsers.filter((uid: number) => userIds.includes(uid));
  }

  private removeClientTags(item: ClientInterface) {
    const foundIndex: number = this.filterClients.findIndex((client) => client === item.id);

    if (foundIndex !== -1) {
      this.filterClients.splice(foundIndex, 1);
    }
  }

  private removeProjectTags(item: ProjectInterface) {
    const foundIndex: number = this.filterProjects.findIndex((project) => project === item.id);

    if (foundIndex !== -1) {
      this.filterProjects.splice(foundIndex, 1);
    }
  }

  private removeUserTags(item: UserInterface) {
    const foundIndex: number = this.filterUsers.findIndex((user) => user === item.id);

    if (foundIndex !== -1) {
      this.filterUsers.splice(foundIndex, 1);
    }
  }
}
