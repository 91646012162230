




















import { Component, Prop } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { TimesheetInterface } from "@/types/timesheet.type";
import { mixins } from "vue-class-component";
import { DurationHelperMixins } from "@/mixins/duration-helper-mixins";
import { JSONToCSVMixin } from "@/mixins/JSONToCSV-mixin";
import { TimeSheetTableForReportData } from "@/types/timesheet-table.type";
import TableComponent from "@/components/TableComponent.vue";
import Header from "@/components/Header.vue";

@Component({
  components: {
    TableComponent,
    Header,
  },
})
export default class TimeSheetTableForReport extends mixins(DurationHelperMixins, JSONToCSVMixin) {
  [x: string]: any;

  @Prop({ required: true })
  private readonly timesheets!: TimesheetInterface[];
  private headers: DataTableHeader[] = [
    {
      text: "Lock",
      value: "lock",
    },
    {
      text: "Date",
      value: "date",
      width: "10%",
    },
    {
      text: "Project",
      value: "projectDisplay",
      width: "10%",
    },
    {
      text: "Client",
      value: "client",
    },
    {
      text: "Description",
      value: "taskDescription",
    },
    {
      text: "Member",
      value: "userDisplay",
    },
    {
      text: "Duration",
      value: "duration",
    },
  ];

  private get displayTimeSheet() {
    return [...this.timesheets].map((t) => {
      const ret = {} as TimeSheetTableForReportData;
      ret.lock = t.lock;
      ret.date = t.date;
      ret.taskDescription = t.taskDescription;
      ret.client = t.clientDisplay;
      ret.projectDisplay = t.projectDisplay;
      ret.duration = t.duration;
      ret.userDisplay = t.userDisplay;
      return ret;
    });
  }
  private exportCSV() {
    this.turnReportPageTimeSheetJSONToCSV(this.displayTimeSheet, this.headers);
  }

  private addRowClasses(item: TimesheetInterface) {
    if (item.lock) {
      return "disable-row";
    }
  }
}
