






















import { mixins } from "vue-chartjs";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { BarAlt } from "@/utils/roundChart";

// this is important to add the functionality to your component
@Component({
  extends: BarAlt,
  mixins: [mixins.reactiveProp],
})
export default class WorktimeChart extends Vue {
  private renderChart!: (chartData: any, options: any) => void;

  @Prop({
    type: Object,
    required: true,
  })
  private chartData: any;

  public mounted() {
    this.buildChartWithTooltip();
  }

  @Watch("chartData")
  private onChartDataChanged(value: object) {
    this.buildChartWithTooltip();
  }

  private buildChartWithTooltip() {
    this.renderChart(this.chartData, {
      maintainAspectRatio: false,
      cornerRadius: 20,
      tooltips: {
        enabled: false,
        displayColors: false, // hide color box
        custom: (tooltipModel: any) => {
          // Tooltip Element
          const tooltipEl = document.getElementById("chartjs-tooltip") as HTMLElement;

          // Hide if no tooltip
          if (!tooltipModel) {
            tooltipEl.style.opacity = "0px";
            return;
          }

          if (tooltipModel.body) {
            const tooltipDetail = document.getElementById("tooltip-detail") as HTMLElement;
            tooltipDetail.textContent = `${tooltipModel.body[0].lines[0].split(": ")[1]}`;
            tooltipEl.style.opacity = "1";
            tooltipEl.style.left = `${tooltipModel.caretX + 20}px`;
            tooltipEl.style.top = `${tooltipModel.caretY + 30}px`;
          } else {
            tooltipEl.style.opacity = "0";
          }
        },
      },
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              stepSize: 1,
              beginAtZero: true,
            },
          },
        ],
        xAxes: [
          {
            gridLines: { display: false },
          },
        ],
      },
    });
  }
}
