// 1. Import Chart.js so you can use the global Chart object
import Chart from "chart.js";
// 2. Import the `generateChart()` method to create the vue component.
import { generateChart } from "vue-chartjs";

// @ts-ignore
Chart.elements.Rectangle.prototype.draw = function () {
  const ctx = this._chart.ctx;
  const vm = this._view;
  let left;
  let right;
  let top;
  let bottom;
  let signX;
  let signY;
  let borderSkipped;
  let borderWidth = vm.borderWidth;

  // If radius is less than 0 or is large enough to cause drawing errors a max
  //      radius is imposed. If cornerRadius is not defined set it to 0.
  let cornerRadius = this._chart.config.options.cornerRadius;
  let fullCornerRadius = this._chart.config.options.fullCornerRadius;
  const typeOfChart = this._chart.config.type;

  if (cornerRadius < 0) {
    cornerRadius = 0;
  }
  if (typeof cornerRadius === "undefined") {
    cornerRadius = 0;
  }
  if (typeof fullCornerRadius === "undefined") {
    fullCornerRadius = false;
  }

  if (!vm.horizontal) {
    // bar
    left = vm.x - vm.width / 2;
    right = vm.x + vm.width / 2;
    top = vm.y;
    bottom = vm.base;
    signX = 1;
    signY = bottom > top ? 1 : -1;
    borderSkipped = vm.borderSkipped || "bottom";
  } else {
    // horizontal bar
    left = vm.base;
    right = vm.x;
    top = vm.y - vm.height / 2;
    bottom = vm.y + vm.height / 2;
    signX = right > left ? 1 : -1;
    signY = 1;
    borderSkipped = vm.borderSkipped || "left";
  }

  // Canvas doesn't allow us to stroke inside the width so we can
  // adjust the sizes to fit if we're setting a stroke on the line
  if (borderWidth) {
    // borderWidth should be less than bar width and bar height.
    const barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
    borderWidth = borderWidth > barSize ? barSize : borderWidth;
    const halfStroke = borderWidth / 2;
    // Adjust borderWidth when bar top position is near vm.base(zero).
    const borderLeft = left + (borderSkipped !== "left" ? halfStroke * signX : 0);
    const borderRight = right + (borderSkipped !== "right" ? -halfStroke * signX : 0);
    const borderTop = top + (borderSkipped !== "top" ? halfStroke * signY : 0);
    const borderBottom = bottom + (borderSkipped !== "bottom" ? -halfStroke * signY : 0);
    // not become a vertical line?
    if (borderLeft !== borderRight) {
      top = borderTop;
      bottom = borderBottom;
    }
    // not become a horizontal line?
    if (borderTop !== borderBottom) {
      left = borderLeft;
      right = borderRight;
    }
  }

  ctx.beginPath();
  ctx.fillStyle = vm.backgroundColor;
  ctx.strokeStyle = vm.borderColor;
  ctx.lineWidth = borderWidth;

  // Corner points, from bottom-left to bottom-right clockwise
  // | 1 2 |
  // | 0 3 |
  const corners = [
    [left, bottom],
    [left, top],
    [right, top],
    [right, bottom],
  ];

  // Find first (starting) corner with fallback to 'bottom'
  const borders = ["bottom", "left", "top", "right"];
  let startCorner = borders.indexOf(borderSkipped, 0);
  if (startCorner === -1) {
    startCorner = 0;
  }

  function cornerAt(index: number) {
    return corners[(startCorner + index) % 4];
  }

  // Draw rectangle from 'startCorner'
  const corner = cornerAt(0);
  ctx.moveTo(corner[0], corner[1]);

  let nextCornerId;
  let width;
  let height;
  let x;
  let y;
  for (let i = 1; i < 4; i++) {
    cornerAt(i);
    nextCornerId = i + 1;
    if (nextCornerId === 4) {
      nextCornerId = 0;
    }

    cornerAt(nextCornerId);

    width = corners[2][0] - corners[1][0];
    height = corners[0][1] - corners[1][1];
    x = corners[1][0];
    y = corners[1][1];

    let radius = cornerRadius;
    // Fix radius being too large
    if (radius > Math.abs(height) / 2) {
      radius = Math.floor(Math.abs(height) / 2);
    }
    if (radius > Math.abs(width) / 2) {
      radius = Math.floor(Math.abs(width) / 2);
    }

    let xTl;
    let xTr;
    let yTl;
    let yTr;
    let xBl;
    let xBr;
    let yBl;
    let yBr;
    if (height < 0) {
      // Negative values in a standard bar chart
      xTl = x;
      xTr = x + width;
      yTl = y + height;
      yTr = y + height;

      xBl = x;
      xBr = x + width;
      yBl = y;
      yBr = y;

      // Draw
      ctx.moveTo(xBl + radius, yBl);

      ctx.lineTo(xBr - radius, yBr);

      // bottom right
      ctx.quadraticCurveTo(xBr, yBr, xBr, yBr - radius);

      ctx.lineTo(xTr, yTr + radius);

      // top right
      fullCornerRadius ? ctx.quadraticCurveTo(xTr, yTr, xTr - radius, yTr) : ctx.lineTo(xTr, yTr, xTr - radius, yTr);

      ctx.lineTo(xTl + radius, yTl);

      // top left
      fullCornerRadius ? ctx.quadraticCurveTo(xTl, yTl, xTl, yTl + radius) : ctx.lineTo(xTl, yTl, xTl, yTl + radius);

      ctx.lineTo(xBl, yBl - radius);

      //  bottom left
      ctx.quadraticCurveTo(xBl, yBl, xBl + radius, yBl);
    } else if (width < 0) {
      // Negative values in a horizontal bar chart
      xTl = x + width;
      xTr = x;
      yTl = y;
      yTr = y;

      xBl = x + width;
      xBr = x;
      yBl = y + height;
      yBr = y + height;

      // Draw
      ctx.moveTo(xBl + radius, yBl);

      ctx.lineTo(xBr - radius, yBr);

      //  Bottom right corner
      fullCornerRadius ? ctx.quadraticCurveTo(xBr, yBr, xBr, yBr - radius) : ctx.lineTo(xBr, yBr, xBr, yBr - radius);

      ctx.lineTo(xTr, yTr + radius);

      // top right Corner
      fullCornerRadius ? ctx.quadraticCurveTo(xTr, yTr, xTr - radius, yTr) : ctx.lineTo(xTr, yTr, xTr - radius, yTr);

      ctx.lineTo(xTl + radius, yTl);

      // top left corner
      ctx.quadraticCurveTo(xTl, yTl, xTl, yTl + radius);

      ctx.lineTo(xBl, yBl - radius);

      //  bttom left corner
      ctx.quadraticCurveTo(xBl, yBl, xBl + radius, yBl);
    } else {
      let lastVisible = 0;
      for (let findLast = 0, findLastTo = this._chart.data.datasets.length; findLast < findLastTo; findLast++) {
        if (!this._chart.getDatasetMeta(findLast).hidden) {
          lastVisible = findLast;
        }
      }
      const rounded = this._datasetIndex === lastVisible;

      if (rounded) {
        // Positive Value
        ctx.moveTo(x + radius, y);

        ctx.lineTo(x + width - radius, y);

        // top right
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius);

        ctx.lineTo(x + width, y + height - radius);

        // bottom right
        if (fullCornerRadius || typeOfChart === "horizontalBar") {
          ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
        } else {
          ctx.lineTo(x + width, y + height, x + width - radius, y + height);
        }

        ctx.lineTo(x + radius, y + height);

        // bottom left
        if (fullCornerRadius) {
          ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
        } else {
          ctx.lineTo(x, y + height, x, y + height - radius);
        }

        ctx.lineTo(x, y + radius);

        // top left
        if (fullCornerRadius || typeOfChart === "bar") {
          ctx.quadraticCurveTo(x, y, x + radius, y);
        } else {
          ctx.lineTo(x, y, x + radius, y);
        }
      } else {
        ctx.moveTo(x, y);
        ctx.lineTo(x + width, y);
        ctx.lineTo(x + width, y + height);
        ctx.lineTo(x, y + height);
        ctx.lineTo(x, y);
      }
    }
  }

  ctx.fill();
  if (borderWidth) {
    ctx.stroke();
  }
};

export const BarAlt = generateChart("custom-bar", "bar");
