import Vue from "vue";
import Component from "vue-class-component";
import { Parser } from "json2csv";
import { DataTableHeader } from "vuetify";
import { TimeSheetTableForProject, TimeSheetTableForReportData } from "@/types/timesheet-table.type";
import { SnackbarModule } from "@/store/modules/snackbar-module";

@Component
export class JSONToCSVMixin extends Vue {
  public turnReportPageTimeSheetJSONToCSV(data: TimeSheetTableForReportData[], headers: DataTableHeader[]) {
    const bundle = this.changeHeader(data, headers);
    this.turnJSONToCSV(bundle.data, bundle.headers, bundle.fileName);
  }
  public turnProjectTimeSheetTabJSONToCSV(data: TimeSheetTableForProject[], headers: DataTableHeader[]) {
    const bundle = this.changeHeader(data, headers);
    this.turnJSONToCSV(bundle.data, bundle.headers, bundle.fileName);
  }

  private changeHeader(data: any[], headers: DataTableHeader[]): { data: any[]; headers: string[]; fileName: string } {
    const newHeaders: DataTableHeader[] = headers.slice(1, headers.length);
    const dataForTable = [];
    let newRow;
    const projects = [...new Set(data.map((d) => d.projectDisplay))].sort(
      (a: string, b: string) => a.length - b.length
    );
    let fileNameWithOutExt = `timesheets ${projects.join(",")}`;
    if (fileNameWithOutExt.length > 200) {
      fileNameWithOutExt = `${fileNameWithOutExt.slice(0, 198)}_`;
    }
    const fileName = `${fileNameWithOutExt}.csv`;
    for (const row of data) {
      newRow = Object();
      for (const { text, value } of newHeaders) {
        newRow[text] = row[value];
      }
      dataForTable.push(newRow);
    }
    return { data: dataForTable, headers: newHeaders.map((e) => e.text), fileName };
  }

  private turnJSONToCSV(data: any[], headers: string[], fileName: string) {
    const fields = headers;
    const withBOM = true;
    const opts = { fields, withBOM };
    try {
      const parser = new Parser(opts);
      const csv = parser.parse(data);
      const blob: Blob = new Blob([csv]);
      const fileURL: string = window.URL.createObjectURL(blob);
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", fileName);
      document.body.appendChild(fileLink);
      SnackbarModule.setSnack({
        color: "success",
        message: "Download CSV successfully! ",
      });
      // @ts-ignore
      // when running in cypress, don't download the file
      if (window.Cypress) {
        return;
      }
      fileLink.click();
      fileLink.remove();
    } catch (err) {
      SnackbarModule.setSnack({
        color: "error",
        message: err,
      });
    }
  }
}
