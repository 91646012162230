import { getModule, Module, MutationAction, VuexModule } from "vuex-module-decorators";
import { TimesheetReportInterface, TimesheetReportRequestInterface } from "@/types/timesheet.type";
import { getTimesheetReport } from "@/services/timesheet.service";
import store from "@/store";

@Module({
  dynamic: true,
  name: "timesheet-report",
  store,
})
class TimesheetReportModule extends VuexModule {
  public timesheetReports: TimesheetReportInterface | null = null;

  @MutationAction({ mutate: ["timesheetReports"] })
  public async getTimesheetReports(filterData: TimesheetReportRequestInterface) {
    const timesheetReports: TimesheetReportInterface = await getTimesheetReport(filterData);
    return { timesheetReports };
  }
}

export default getModule(TimesheetReportModule);
