import moment from "moment";

// Pad number with leading zero incase the number is smaller than 10, useful for date and month
export function padNumber(n: number) {
  return n < 10 ? "0" + n : n;
}

// Generate 30 day date range
export function generateThirtyAgoRange() {
  const curDate = moment().format("YYYY-MM-DD");
  const thirtyAgoDate = moment().subtract(30, "days").format("YYYY-MM-DD");
  return [thirtyAgoDate, curDate];
}
